<template>
    <div class="container">
        <header>
            <div class="nav-box flex justify align">
                <img src="@/assets/images/logo1.png" alt="">
                <el-tabs v-model="activeName" @tab-click="changePages">
                    <el-tab-pane v-for="item in tabList" :key="item.key" :label="item.label" :name="item.key"></el-tab-pane>
                </el-tabs>
            </div>
        </header>
        <main>
            <div class="chatBtn flex align" @click="toChat">
                <i class="el-icon-chat-dot-round"></i>
                在线咨询
            </div>
            <el-dialog title="在线咨询" :visible.sync="dialogVisible" width="400px">
                <iframe id="iframe" src="https://work.weixin.qq.com/kfid/kfce870ae5e192d886f"></iframe>
            </el-dialog>
            <router-view />
        </main>
        <footer>
            <div class="call flex justify align">
                <div class="num">
                    <p>服务热线: 0775-23768967 </p>
                    <p>余&nbsp;经&nbsp;理&nbsp;: 181-2639-6296 </p>
                </div>
                <div class="qrcode">
                    <p>关注我们</p>
                    <img src="@/assets/images/qrcode.jpg" alt="">
                </div>
            </div>
            <div class="addr">
                <p>深圳龙华区优城商务大厦 B 座1305 </p>
                <p>深圳信可为科技有限公司&nbsp;&nbsp;@版权所有 <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2022065638号-2</a>
                </p>
            </div>
        </footer>
    </div>
</template>

<script>

export default {
    name: 'index',
    data() {
        return {
            activeName: '1',
            tabList: [
                { label: '首页', key: '1', path: '/' },
                { label: '关于我们', key: '2', path: '/about' },
                { label: '产品中心', key: '3', path: '/project' },
                { label: '行业资讯', key: '4', path: '/information' },
                { label: '联系我们', key: '5', path: '/contact' },
            ],
            dialogVisible: false,
        }
    },

    mounted() {
        this.tabList.map(item => {
            if (item.path == this.$route.path) {
                this.activeName = item.key
                return
            }
        })

    },
    methods: {
        changePages(path) {
            if (this.tabList[path._data.index].path != this.$route.path) {
                this.$router.push(this.tabList[path._data.index].path)
            }
        },
        toChat() {
            this.dialogVisible = true
            let ifl = document.getElementById('iframe')
            ifl.src = 'https://work.weixin.qq.com/kfid/kfce870ae5e192d886f'
            // if (navigator.platform == 'darwin') {
            //     window.open('https://work.weixin.qq.com/kfid/kfce870ae5e192d886f', '_blank')
            // }
        },
    },
}
</script>

<style lang="scss" scope>
header {
    .nav-box {
        position: fixed;
        z-index: 99999;
        top: 0%;
        padding: 0 5vw;
        box-sizing: border-box;
        width: 100vw;
        height: 50px;
        // padding: auto;
        background: rgba(233, 233, 234, 0.5);

        img {
            width: 100px;
        }

        .el-tabs {
            .el-tabs__item {
                color: #333;
            }

            .el-tabs__item:hover {
                color: #04C8FC;
            }

            .el-tabs__item.is-active {
                color: #04C8FC;
            }

            .el-tabs__active-bar {
                background-color: #04C8FC;
            }

            .el-tabs__nav-wrap::after {
                width: 0;
            }
        }
    }

}

main {
    .chatBtn {
        position: fixed;
        z-index: 999999;
        right: .5%;
        // bottom: 0%;
        top: 50%;
        background: #FFF;
        box-shadow: 2px 2px 10px #c5c5c5;
        padding: 10px;
        cursor: pointer;
        border-radius: 10px;
        font-size: 12px;
        transform: translate(0, -50%);
        flex-direction: column;

        i {
            font-size: 30px;
        }
    }

    iframe {
        width: 100%;
        height: 480px;
        border: 0;
    }

    .contain-box {
        padding: 50px 5vw;
        line-height: 30px;
        box-sizing: border-box;

        .title {
            justify-content: center;
            align-items: end;
            margin-bottom: 60px;
            font-size: 20px;
            font-weight: 300;
            font-size: 18px;
            text-align: center;
            color: #04C8FC;

            p {
                font-weight: 400;
                font-size: 24px;
            }
        }

    }
}

footer {
    background: rgba(4, 27, 80, 0.5);
    color: #FFF;
    text-align: center;
    padding: 10px 0;
    width: 100%;

    .call {
        width: 550px;
        margin: auto;
        font-size: 24px;
        padding-left: 15vw;

        .num p {
            text-align: center;
            line-height: 40px;
        }

        .qrcode {
            img {
                width: 100px;
            }

            p {
                font-size: 16px;
                margin-bottom: 5px;

            }
        }
    }

    .addr {
        font-size: 12px;
        color: #f7f7f7;
    }



    a {
        color: #FFF;
        text-decoration: none;
    }

    a:hover {
        color: #34b1fa;
    }


}

/* 根据不同屏幕宽度设置样式 */
@media (max-width: 767px) {

    /* 手机屏幕样式 */
    header {
        // position: relative;

        .nav-box {
            position: fixed;
            top: 0%;
            background: #ffffff80;
            box-sizing: border-box;

            img {
                width: 60px;
            }

            .el-tabs {

                .el-tabs__item {
                    padding: 4px;
                    font-size: 12px;
                }
            }
        }

    }

    main {
        .contain-box {
            .title {
                align-items: end;
                font-size: 14px;

                p {
                    font-size: 18px;
                }
            }
        }

    }

    footer {

        .call {
            width: 60vw;
            padding-left: 0;

            .num {
                font-size: 14px;

                p {
                    line-height: 30px;
                }
            }

            .qrcode {
                img {
                    width: 50px;
                }

                p {
                    font-size: 12px;
                }
            }
        }

        .addr {
            font-size: 10px;
        }


    }
}

@media (min-width: 768px) and (max-width: 1023px) {

    /* 平板屏幕样式 */
    header {

        .nav-box {
            .el-tabs {
                margin-left: 10px;
            }
        }
    }

    main {
        .contain-box {
            .title {
                align-items: end;
                font-size: 14px;

                p {
                    font-size: 20px;
                }
            }
        }

    }

    footer {
        .call {
            width: 450px;
        }

        .addr {
            font-size: 14px;
        }
    }

}


@media (min-width: 1024px) {

    /* 桌面屏幕样式 */
    header {
        .nav-box {
            padding: 0 10vw;

            .el-tabs {
                margin-left: 10px;
            }
        }
    }

    main {
        .contain-box {
            padding: 80px 10vw;
            box-sizing: border-box;
        }
    }

}
</style>