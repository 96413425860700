import VueRouter from "vue-router";

// 创建并暴露一个路由实例
export default new VueRouter({
    mode: 'hash', // history去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        // {
        //     path: '/',
        //     component: () => import('@/pages/index'),
        // },
        {
            path: '/',
            component: () => import('@/pages/home'),
        },
        {
            path: '/about',
            component: () => import('@/pages/about'),
        },
        {
            path: '/contact',
            component: () => import('@/pages/contact'),
        },
        {
            path: '/project',
            component: () => import('@/pages/project'),
        },
        {
            path: '/information',
            component: () => import('@/pages/information'),
        }
        ,
        {
            path: '/container',
            component: () => import('@/pages/container'),
        }
    ],

})