<template>
  <div id="app">
    <index />
  </div>
</template>

<script>
import index from './pages/index'
export default {
  name: 'App',
  components: {index}
}
</script>